.articleOrderEdit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  h1 {
    margin-bottom: 2rem;
    margin-top: 1rem;
    text-align: center;
    font-size: xx-large;
  }

  .subtitle, .orderDetail {
    text-align: center;
    margin: 1rem 0;
    font-size: medium;
    color: #8c8c8c;
  }

}

.calenderFormLabel {
  font-size: 1rem;
  text-align: center;
}
