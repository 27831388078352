// mixins for grid system
// ------------------------
.gx-make-row() {
  margin-left: (@grid-gutter-width / -2);
  margin-right: (@grid-gutter-width / -2);
}

.make-grid-columns() {
  .col(@index) {
    @item: ~'.@{ant-prefix}-col-@{index}, .@{ant-prefix}-col-xs-@{index}, .@{ant-prefix}-col-sm-@{index}, .@{ant-prefix}-col-md-@{index}, .@{ant-prefix}-col-lg-@{index}';
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) {
    @item: ~'.@{ant-prefix}-col-@{index}, .@{ant-prefix}-col-xs-@{index}, .@{ant-prefix}-col-sm-@{index}, .@{ant-prefix}-col-md-@{index}, .@{ant-prefix}-col-lg-@{index}';
    .col((@index + 1), ~'@{list}, @{item}');
  }
  .col(@index, @list) when (@index > @grid-columns) {
    @{list} {
      // Prevent columns from collapsing when empty
      padding-left: (@grid-gutter-width / 2);
      padding-right: (@grid-gutter-width / 2);
      float: none !important;
    }
  }
  .col(1);
}

// Grid system
.ant-row {
  .gx-make-row();
  .flex-display(flex, row, wrap);

  & > [class*='ant-col-'] {
    margin: 0 -0.3px;
  }
}

.ant-col-1,
.ant-col-xs-1,
.ant-col-sm-1,
.ant-col-md-1,
.ant-col-lg-1,
.ant-col-2,
.ant-col-xs-2,
.ant-col-sm-2,
.ant-col-md-2,
.ant-col-lg-2,
.ant-col-3,
.ant-col-xs-3,
.ant-col-sm-3,
.ant-col-md-3,
.ant-col-lg-3,
.ant-col-4,
.ant-col-xs-4,
.ant-col-sm-4,
.ant-col-md-4,
.ant-col-lg-4,
.ant-col-5,
.ant-col-xs-5,
.ant-col-sm-5,
.ant-col-md-5,
.ant-col-lg-5,
.ant-col-6,
.ant-col-xs-6,
.ant-col-sm-6,
.ant-col-md-6,
.ant-col-lg-6,
.ant-col-7,
.ant-col-xs-7,
.ant-col-sm-7,
.ant-col-md-7,
.ant-col-lg-7,
.ant-col-8,
.ant-col-xs-8,
.ant-col-sm-8,
.ant-col-md-8,
.ant-col-lg-8,
.ant-col-9,
.ant-col-xs-9,
.ant-col-sm-9,
.ant-col-md-9,
.ant-col-lg-9,
.ant-col-10,
.ant-col-xs-10,
.ant-col-sm-10,
.ant-col-md-10,
.ant-col-lg-10,
.ant-col-11,
.ant-col-xs-11,
.ant-col-sm-11,
.ant-col-md-11,
.ant-col-lg-11,
.ant-col-12,
.ant-col-xs-12,
.ant-col-sm-12,
.ant-col-md-12,
.ant-col-lg-12,
.ant-col-13,
.ant-col-xs-13,
.ant-col-sm-13,
.ant-col-md-13,
.ant-col-lg-13,
.ant-col-14,
.ant-col-xs-14,
.ant-col-sm-14,
.ant-col-md-14,
.ant-col-lg-14,
.ant-col-15,
.ant-col-xs-15,
.ant-col-sm-15,
.ant-col-md-15,
.ant-col-lg-15,
.ant-col-16,
.ant-col-xs-16,
.ant-col-sm-16,
.ant-col-md-16,
.ant-col-lg-16,
.ant-col-17,
.ant-col-xs-17,
.ant-col-sm-17,
.ant-col-md-17,
.ant-col-lg-17,
.ant-col-18,
.ant-col-xs-18,
.ant-col-sm-18,
.ant-col-md-18,
.ant-col-lg-18,
.ant-col-19,
.ant-col-xs-19,
.ant-col-sm-19,
.ant-col-md-19,
.ant-col-lg-19,
.ant-col-20,
.ant-col-xs-20,
.ant-col-sm-20,
.ant-col-md-20,
.ant-col-lg-20,
.ant-col-21,
.ant-col-xs-21,
.ant-col-sm-21,
.ant-col-md-21,
.ant-col-lg-21,
.ant-col-22,
.ant-col-xs-22,
.ant-col-sm-22,
.ant-col-md-22,
.ant-col-lg-22,
.ant-col-23,
.ant-col-xs-23,
.ant-col-sm-23,
.ant-col-md-23,
.ant-col-lg-23,
.ant-col-24,
.ant-col-xs-24,
.ant-col-sm-24,
.ant-col-md-24,
.ant-col-lg-24 {
  padding-left: (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
  float: none !important;
}

.@{class-prefix}-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: (@grid-gutter-width / 2);
  padding-right: (@grid-gutter-width / 2);
}

.@{class-prefix}-col-full {
  @media screen and (max-width: (@screen-xs-max - 95px)) {
    width: 100%;
  }
}

.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}

.articleLength {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
  gap: 10px !important;
}

@media screen and (max-width: @screen-xs-max) {
  .articleLength {
    display: flex !important;
    flex-direction: column !important;
    float: left !important;
    column-gap: 10px !important;
    .articleLengthCustom {
      width: 100% !important;
    }
  }
}
