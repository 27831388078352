
.interviewNote{
    h1{
        margin-bottom: 2rem;
         margin-top: 1rem;
    }
}
.noteArea{
    height: 15rem !important;
}
.buttonStyle{
    width: 25rem;
}

.modalBtnGrp{
display: flex;
justify-content: space-between;
gap: 1rem;

}

.modalBtnStyle{
    width:50%;
}
@media screen and (max-width: @screen-md-max){
  .modalBtnGrp {
    display: flex;
    flex-direction: column;
  }
  .modalBtnStyle{
    width:100%;
}
}


