.headerDivWrapper{
   display: flex;
   justify-content: space-between ;

}
.panelHeaderWrapper{
    display: flex;
    justify-content: space-between ;
    div{
       display: flex;
       flex-direction: column;
    }
}
.interviewDetail{
   border-bottom: 1px solid black;
}
