.commitment-agreement{
  .ant-collapse-item  {
    .ant-collapse-header{
      display: flex;
      align-items: center;
      .ant-collapse-header-text {
        h2 {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
